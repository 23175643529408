import Modal from '@material-ui/core/Modal';
import { Container } from 'components/common';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';
import ProjectCard from './projectCard';
import ProjectDetailView from './projectDetailView';

export const Projects = () => {
  const tags = {
    design: 'Design',
    frontend: 'Frontend',
    backend: 'Backend',
    mobile: 'Mobile',
  };
  const data = useStaticQuery(graphql`
    {
      airnoiseImage: file(
        relativePath: { eq: "projectImages/airnoise/airnoise_featured.png" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(
            transformOptions: { cropFocus: CENTER }
            layout: FULL_WIDTH
          )
        }
      }
      airnoise1: file(relativePath: { eq: "projectImages/airnoise/1.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      airnoise2: file(relativePath: { eq: "projectImages/airnoise/2.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      airnoise4: file(relativePath: { eq: "projectImages/airnoise/4.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      airnoise5: file(relativePath: { eq: "projectImages/airnoise/5.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      airnoise6: file(relativePath: { eq: "projectImages/airnoise/6.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      measureMusicImage: file(
        relativePath: {
          eq: "projectImages/measureMusic/measureMusic_featured.png"
        }
      ) {
        id
        childImageSharp {
          gatsbyImageData(
            transformOptions: { cropFocus: CENTER }
            layout: FULL_WIDTH
          )
        }
      }
      measureMusic1: file(
        relativePath: { eq: "projectImages/measureMusic/1.png" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      measureMusic2: file(
        relativePath: { eq: "projectImages/measureMusic/2.png" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      measureMusic3: file(
        relativePath: { eq: "projectImages/measureMusic/3.png" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      measureMusic4: file(
        relativePath: { eq: "projectImages/measureMusic/4.png" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      flowProImage: file(
        relativePath: { eq: "projectImages/flowPro/flowPro_featured.png" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(
            transformOptions: { cropFocus: CENTER }
            layout: FULL_WIDTH
          )
        }
      }
      flowPro1: file(relativePath: { eq: "projectImages/flowPro/1.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      flowPro2: file(relativePath: { eq: "projectImages/flowPro/2.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      flowPro3: file(relativePath: { eq: "projectImages/flowPro/3.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      flowPro4: file(relativePath: { eq: "projectImages/flowPro/4.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      flowPro5: file(relativePath: { eq: "projectImages/flowPro/5.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      schoolAppImage: file(
        relativePath: { eq: "projectImages/schoolApp/schoolApp_featured.png" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(
            transformOptions: { cropFocus: CENTER }
            layout: FULL_WIDTH
          )
        }
      }
      schoolApp1: file(relativePath: { eq: "projectImages/schoolApp/1.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      schoolApp2: file(relativePath: { eq: "projectImages/schoolApp/2.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      schoolApp3: file(relativePath: { eq: "projectImages/schoolApp/3.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      schoolApp4: file(relativePath: { eq: "projectImages/schoolApp/4.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      schoolApp5: file(relativePath: { eq: "projectImages/schoolApp/5.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      treeMediaFeedImage: file(
        relativePath: {
          eq: "projectImages/treeMediaFeed/treeMediaFeed_featured.png"
        }
      ) {
        id
        childImageSharp {
          gatsbyImageData(
            transformOptions: { cropFocus: CENTER }
            layout: FULL_WIDTH
          )
        }
      }
      treeMediaFeed1: file(
        relativePath: { eq: "projectImages/treeMediaFeed/1.png" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      treeMediaFeed2: file(
        relativePath: { eq: "projectImages/treeMediaFeed/2.png" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      treeMediaFeed3: file(
        relativePath: { eq: "projectImages/treeMediaFeed/3.png" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      treeMediaFeed4: file(
        relativePath: { eq: "projectImages/treeMediaFeed/4.png" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      artCircutImage: file(
        relativePath: { eq: "projectImages/artCircut/artCircut_featured.png" }
      ) {
        id
        childImageSharp {
          gatsbyImageData(
            transformOptions: { cropFocus: CENTER }
            layout: FULL_WIDTH
          )
        }
      }
      artCircut1: file(relativePath: { eq: "projectImages/artCircut/1.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      artCircut2: file(relativePath: { eq: "projectImages/artCircut/2.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      artCircut3: file(relativePath: { eq: "projectImages/artCircut/3.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      artCircut4: file(relativePath: { eq: "projectImages/artCircut/4.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      artCircut5: file(relativePath: { eq: "projectImages/artCircut/5.png" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  const projects = [
    {
      title: 'MeasureMusic',
      summary: `I was the lead frontend developer on the MeasureMusic project. 
        MeasureMusic is a music analytics platform designed for industry professionals. 
        I was responsible for building the front-end from the ground up.
         I created tables and graphs for users to sort and filter 
         large amounts of data. I also developed front-end auth restrictions to restrict 
         certain pages from being accessed by users with insufficent membership tiers.`,
      featuredImage: data.measureMusicImage.childImageSharp.gatsbyImageData,
      images: [
        data.measureMusic1.childImageSharp.gatsbyImageData,
        data.measureMusic2.childImageSharp.gatsbyImageData,
        data.measureMusic3.childImageSharp.gatsbyImageData,
        data.measureMusic4.childImageSharp.gatsbyImageData,
      ],
      tags: [tags.frontend],
      website: 'https://www.measuremusic.com/',
      technologies: [
        'React',
        'Redux',
        'JavaScript',
        'Styled-Components',
        'CSS',
        'HTML5',
        'REST API',
      ],
    },
    {
      title: 'FlowPro',
      summary: `I worked on the frontend and backend for FlowPro, a form building platform for use in the medical field. FlowPro allows medical staff, such as doctors and nurses, to create forms for their patients. FlowPro was a product that involved a lot of user permissioning and security for sensitive medical data, so we had to make sure that the user data was kept safe and secure, and that the user experience was easy to navigate.
        This project was quite challenging because it involved sensitive data as well as a very granular user permissioning setup. 
        `,
      featuredImage: data.flowProImage.childImageSharp.gatsbyImageData,
      images: [
        data.flowPro1.childImageSharp.gatsbyImageData,
        data.flowPro2.childImageSharp.gatsbyImageData,
        data.flowPro3.childImageSharp.gatsbyImageData,
        data.flowPro4.childImageSharp.gatsbyImageData,
        data.flowPro5.childImageSharp.gatsbyImageData,
      ],
      tags: [tags.frontend, tags.backend],
      technologies: [
        'React',
        'Redux',
        'JavaScript',
        'Styled-Components',
        'Material-UI',
        'MochaJS',
        'Typescript',
        'CSS',
        'HTML5',
        'Fusion Auth',
      ],
    },
    {
      title: 'Tree Media Feed',
      summary: `I was the tech lead for the Tree Media Feed project, which is a social platform for sharing full resolution photos and video. On paper the project seemed straight forward but there were a lot of interesting challenges both in terms of UX and on the backend. One Challenge I faced on the frontend side of things was creating a gesture handler that allowed the user to scroll through a feed of photos, swipe horizontally to access other screens, pinch to zoom into a photo, and double tap to like a photo, all from the same view. On the backend side there were a lot of technical challenges with getting the application to scale. In the end I was able to find work through these challenges and ended up with a nice user experience.`,
      featuredImage: data.treeMediaFeedImage.childImageSharp.gatsbyImageData,
      images: [
        data.treeMediaFeed1.childImageSharp.gatsbyImageData,
        data.treeMediaFeed2.childImageSharp.gatsbyImageData,
        data.treeMediaFeed3.childImageSharp.gatsbyImageData,
        data.treeMediaFeed4.childImageSharp.gatsbyImageData,
      ],
      tags: [tags.frontend, tags.backend, tags.mobile],
      website:
        'https://play.google.com/store/apps/details?id=com.treemediafeed.mobile&hl=en_US&gl=US',
      technologies: [
        'Javascript',
        'React Native',
        'Redux',
        'Expo',
        'AWS Amplify',
        'AWS Appsync',
        'AWS S3',
        'AWS Cognito',
        'Dynamo DB',
        'Graphql',
      ],
    },
    {
      title: 'ArtCircut',
      summary: `
      ArtCircut is a side project of mine that I have been developing in react native and NextJs. It's a platform for users to manage their art portfolio and I am currently working on a feature to generate a portfolio website that the artist can customize. 
      I have also incoroprated an Augmented reality feature where users can view art works in their physical space all from within the web app.

      I was inspired to create ArtCircut becuase it is a tool that I wanted for managaing my own art works and could not find an existing platform online that did everything I wanted it to. ArtCircut is still a work in progress and I am currently on the second iteration of the project. It has been a great project for me to tryout new technologies wheter it be a new framework like NextJs or a new web feature like webXR. 
      `,
      featuredImage: data.artCircutImage.childImageSharp.gatsbyImageData,
      images: [
        data.artCircut1.childImageSharp.gatsbyImageData,
        data.artCircut2.childImageSharp.gatsbyImageData,
        data.artCircut3.childImageSharp.gatsbyImageData,
        data.artCircut4.childImageSharp.gatsbyImageData,
        data.artCircut5.childImageSharp.gatsbyImageData,
      ],
      tags: [tags.frontend, tags.design, tags.backend],
      technologies: [
        'Javascript',
        'React Native',
        'Redux',
        'Expo',
        'AWS Amplify',
        'AWS Appsync',
        'AWS S3',
        'AWS Cognito',
        'Dynamo DB',
        'Graphql',
        'NextJS',
      ],
    },
    {
      title: 'Airnoise.io',
      summary:
        'For this project I designed responsive landing page components and developed a feature where users could see a map real time air noise complaints in the United States. The map is interactive so you are able to zoom in on each region and see more data about the complaint. My primary goal was to make the site more visually appealing while adding a new layer of engagement.',
      featuredImage: data.airnoiseImage.childImageSharp.gatsbyImageData,
      images: [
        data.airnoise1.childImageSharp.gatsbyImageData,
        data.airnoise2.childImageSharp.gatsbyImageData,
        data.airnoiseImage.childImageSharp.gatsbyImageData,
        data.airnoise4.childImageSharp.gatsbyImageData,
        data.airnoise5.childImageSharp.gatsbyImageData,
        data.airnoise6.childImageSharp.gatsbyImageData,
      ],
      tags: [tags.frontend, tags.design, tags.backend],
      website: 'https://airnoise.io/',
      technologies: [
        'Ruby',
        'Ruby on Rails',
        'CSS',
        'HTML5',
        'postgreSQL',
        'Action Cable - websockets',
      ],
    },
    {
      title: 'SchoolApp',
      summary:
        'I worked on the front-end for SchoolApp , helping the team design, build, and deliver an intuitive interface for teachers to easily create events, manage course schedules, share student information, and more. My contributions included designing new features, testing the product with users, and implementing designs into production code.',
      featuredImage: data.schoolAppImage.childImageSharp.gatsbyImageData,
      images: [
        data.schoolApp1.childImageSharp.gatsbyImageData,
        data.schoolApp2.childImageSharp.gatsbyImageData,
        data.schoolApp3.childImageSharp.gatsbyImageData,
        data.schoolApp4.childImageSharp.gatsbyImageData,
        data.schoolApp5.childImageSharp.gatsbyImageData,
      ],
      tags: [tags.frontend, tags.mobile],
      technologies: ['Javascript', 'React Native', 'Redux', 'REST API'],
    },
  ];

  const [selectedIndex, setSelectedIndex] = useState(null);

  const setIndex = (index) => {
    if (index == selectedIndex) {
      setSelectedIndex(null);
    } else {
      setSelectedIndex(index);
    }
  };

  const handleClose = () => {
    setSelectedIndex(null);
  };

  return (
    <>
      <Modal
        open={selectedIndex !== null}
        onClose={handleClose}
        aria-labelledby='project-view-modal'
      >
        <ProjectDetailView
          close={handleClose}
          project={projects[selectedIndex]}
        />
      </Modal>

      <Wrapper as={Container} id='projects'>
        <Heading>Projects</Heading>
        <Grid>
          {projects.map((project, i) => (
            <ProjectCard
              key={i}
              project={project}
              selected={selectedIndex == i}
              setSelected={setIndex}
              index={i}
            />
          ))}
        </Grid>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  padding: 2rem 0;
`;
const Heading = styled.h2``;
const Grid = styled.div`
  margin-top: 1rem;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 8fr;
  gap: 2rem 2rem;

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;
