import { Html, useProgress } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import React, { Suspense } from 'react';
import styled from 'styled-components';
import HeadModel from './threeJsHead';

function ThreeJsCanvas() {
  return (
    <StyledCanvas camera={{ fov: 50, position: [0, 0, 0.6] }}>
      <Suspense fallback={<Loader />}>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <HeadModel />
      </Suspense>
    </StyledCanvas>
  );
}

export default ThreeJsCanvas;

function Loader() {
  const { progress } = useProgress();
  return <Html center>{progress} % loaded</Html>;
}

const StyledCanvas = styled(Canvas)`
  display: flex;
  flex: 1;

  @media (max-width: 960px) {
    min-height: 40vh;
  }
`;
