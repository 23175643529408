import dev from 'assets/photos/sebastian_white_drawing.jpg';
import { Container } from 'components/common';
import { ThemeContext } from 'providers/ThemeProvider';
import React, { useContext } from 'react';
import styled from 'styled-components';

export const Skills = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id='about'>
      <SkillsWrapper as={Container}>
        <ProfilePic>
          <StyledImg
            src={dev}
            alt='I’m Sebastian and I’m a fullstack engineer!'
          />
        </ProfilePic>
        <Details theme={theme}>
          <h1>More about me</h1>
          <p>
            My name is Sebastian White and I am a full stack web developer that
            is always eager to build new things. Having worked in many startup
            environments I have had to wear many different hats, whether it be
            frontend, backend, project lead or designer, though front-end is
            where I spend most my time. Regardless of the position I am always
            looking forward to building something great!
          </p>
          <p>
            I come from a strong creative background, having started my web
            development journey at an art studio where I would do web design in
            exchange for classes. My passion for creating things has always
            spanned multiple disciplines whether it be programming, painting,
            drawing, photography, music, or a mix them.
          </p>
          <p>
            My mission is to develop awesome projects, and to never stop
            learning!
          </p>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 5rem 0;
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
`;

const SkillsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 2rem;
  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;

const Details = styled.div`
  flex: 1;
  padding-left: 2rem;

  @media (max-width: 960px) {
    padding-left: unset;
    width: 100%;
  }

  h1 {
    margin-bottom: 1rem;
    font-size: 1.6rem;
    color: #212121;
  }

  p {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.3;
    color: #707070;
  }
`;

const ProfilePic = styled.div`
  margin: auto;
  width: 300px;
  height: 300px;
  overflow: hidden;
  border-radius: 50%;
  border: 4px solid #534b69;
  @media (max-width: 960px) {
    width: 200px;
    height: 200px;
  }
`;
const StyledImg = styled.img`
  height: 300;
  width: 300;
  object-fit: cover;
`;
