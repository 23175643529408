import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { animated, config, useTrail, useTransition } from '@react-spring/web';
import { Button } from 'components/common';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ImageGallery from './imageGallery';

const ProjectDetailView = ({ project, close }) => {
  const [show, set] = useState(false);
  //spring animation setup
  const transitions = useTransition(show, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: show,
    delay: 200,
    config: config.stiff,
  });

  useEffect(() => {
    set(true);
  });

  const openProjectSite = () => {
    if (project.website) {
      window.open(project.website, '_blank');
    }
  };

  const sortedTechnologies = project.technologies.sort();

  return transitions(
    (styles, item) =>
      item && (
        <Content>
          <Box display='flex' alignItems='center'>
            <IconButton
              onClick={close}
              aria-label='close'
              style={{ margin: '0.5rem' }}
            >
              <ArrowBackIcon style={{ color: 'black' }} />
            </IconButton>
            <Title>{project.title}</Title>
          </Box>
          <ImageGallery images={project.images} />
          <Box p={'2rem'}>
            <Trail open={show}>
              <Box>
                <Subtitle>About This Project</Subtitle>
                <BodyText>{project.summary}</BodyText>
              </Box>
              <Box mt='2rem'>
                <Subtitle>Technical Sheet</Subtitle>
                <Box mt={'1rem'}>
                  <Trail open={show}>
                    {sortedTechnologies.map((item, i) => (
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '0.5rem',
                          fontSize: '1.2rem',
                        }}
                      >
                        <FiberManualRecordIcon
                          style={{
                            fontSize: 12,
                            marginRight: '0.5rem',
                            color: '#424242',
                          }}
                        />
                        {item}
                      </span>
                    ))}
                  </Trail>
                </Box>
              </Box>
              {project?.website && (
                <Box mt='2rem' display='flex' justifyContent='flex-end'>
                  <Button onClick={openProjectSite} secondary>
                    Visit Website
                  </Button>
                </Box>
              )}
            </Trail>
          </Box>
          <Transition />
        </Content>
      )
  );
};

const Trail: React.FC<{ open: boolean }> = ({ open, children }) => {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    delay: 300,
    from: { opacity: 0, x: 20 },
  });
  return (
    <div>
      {trail.map(({ ...style }, index) => (
        <animated.div key={index} style={style}>
          <animated.div>{items[index]}</animated.div>
        </animated.div>
      ))}
    </div>
  );
};

export default ProjectDetailView;

const Content = styled(animated.div)`
  background: #eae2e0;
  border-radius: 10px;
  max-width: 1200px;
  overflow-y: scroll;
  max-height: 92vh;
  margin: 2rem auto;
  @media (max-width: 1200px) {
    margin: 2rem;
  }
`;
const BodyText = styled.p`
  line-height: 1.5rem;
`;

const Transition = styled.h2`
  color: white;
  margin-bottom: 0;
  margin-left: 0.5rem;
`;
const Title = styled.h2`
  margin-bottom: 0;
`;
const Subtitle = styled.h3`
  margin-bottom: 0.5rem;
  color: #212121;
  padding-left: 0.5rem;
  border-left: 2px solid #171c4d;
`;
