/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF, useTexture } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import React, { useEffect, useRef, useState } from 'react';
import { useWindowDimensions } from '../../../hooks/useWindowHook';
export default function HeadModel(props) {
  const group = useRef();
  const { size } = useThree();
  const { x, y } = useMousePosition();
  const { width, height } = useWindowDimensions();
  const { nodes, materials } = useGLTF('/models/cleanedScan.glb');
  const texture = useTexture('/textures/matcap_1_128px.png');

  const clamp = (num) => Math.min(Math.max(num, -1), 2);

  useFrame((state) => {
    if (group.current) {
      if (width > 960) {
        group.current.rotation.y = clamp(
          (x - size.width * 0.5) / (width * 0.5)
        );
        group.current.rotation.x = clamp(
          (y - size.height * 0.5) / (height * 0.5)
        );
      } else {
        group.current.rotation.y = Math.sin(state.clock.elapsedTime) + 1;
      }
    }
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.cleanedscan.geometry}
        material={materials['Default OBJ']}
        position={[0, 0, 0]}
        rotation={[Math.PI / 2, 0, 1]}
        scale={[1, 1, 1]}
      >
        <meshMatcapMaterial matcap={texture} />
      </mesh>
    </group>
  );
}

const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });

  const updateMousePosition = (ev) => {
    setMousePosition({ x: ev.clientX, y: ev.clientY });
  };

  useEffect(() => {
    window.addEventListener('mousemove', updateMousePosition);

    return () => window.removeEventListener('mousemove', updateMousePosition);
  }, []);

  return mousePosition;
};

useGLTF.preload('/models/cleanedScan.glb');
