import { Button, Container } from 'components/common';
import { Header } from 'components/theme';
import { ThemeContext } from 'providers/ThemeProvider';
import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import styled from 'styled-components';
import { Details, Wrapper } from './styles';
import ThreeJsCanvas from './threeJsCanvas';

export const Intro = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper>
      <Header />
      <Container>
        <HeroContainer>
          <Left>
            <Details theme={theme}>
              <h1>Hello!</h1>
              <h4>I’m Sebastian and I’m a Fullstack engineer!</h4>
              {/* <Button as={AnchorLink} href='#contact'>
                Hire me
              </Button> */}
              <Button as={AnchorLink} href='#contact'>
                Hire Me!
              </Button>
            </Details>
          </Left>

          <ThreeJsCanvas />
        </HeroContainer>
      </Container>
    </Wrapper>
  );
};

const HeroContainer = styled.div`
  display: flex;
  height: 500px;
  margin: auto;

  @media (max-width: 960px) {
    flex-direction: column-reverse;
    height: 100%;
  }
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
