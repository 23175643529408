import { Box } from '@material-ui/core';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

const ProjectCard = ({ setSelected, selected, project, index }) => {
  return (
    <Item onClick={() => setSelected(index)}>
      <TopImage>
        <GatsbyImage
          style={{ height: 200 }}
          image={project.featuredImage}
          alt={project.title}
          objectFit='cover'
        />
      </TopImage>

      <Bottom>
        <ProjectTitle>{project.title}</ProjectTitle>
        <Box maxHeight={120}>
          <ProjectDescription>{project.summary}</ProjectDescription>
        </Box>

        <TagContainer>
          {project.tags.map((tag, i) => (
            <TagName>{tag}</TagName>
          ))}
        </TagContainer>
      </Bottom>
    </Item>
  );
};

export default ProjectCard;

const ProjectTitle = styled.h5`
  color: #212121;
  font-size: 1.4rem;
`;
const TagContainer = styled.div`
  display: flex;
`;
const TagName = styled.div`
  font-size: 0.9rem;
  border-radius: 10px;
  background-color: rgba(128, 128, 128, 0.2);
  margin-right: 0.5rem;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
`;
const ProjectDescription = styled.p`
  color: #212121;
  font-size: 1rem;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;
const Bottom = styled.div`
  padding: 1rem;
`;
const TopImage = styled.div`
  overflow: hidden;
`;

const Item = styled.button`
  border: none;
  padding: 0;
  text-align: left;
  cursor: pointer;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #212121;
  border-radius: 8px;
  background-color: #eae2e0;
  box-shadow: 10px 10px 20px #b4adac, -10px -10px 20px #e4ddda;
`;
