import { Container } from 'components/common';
import React from 'react';
import styled from 'styled-components';
import ContactForm from './ContactForm';

export const Contact = () => {
  return (
    <Wrapper as={Container} id='contact'>
      <Details>
        <Heading>Contact me. Let's Build Something.</Heading>
        <ContactForm />
      </Details>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Details = styled.div`
  margin: auto;
`;
const Heading = styled.div`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;
