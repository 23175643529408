import { Box, Button, useTheme } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { animated } from '@react-spring/web';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import styled from 'styled-components';

interface ImageGalleryProps {
  images: IGatsbyImageData[];
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const theme = useTheme();

  const nextPhoto = () => {
    if (photoIndex < images.length - 1) {
      setPhotoIndex(photoIndex + 1);
    } else if (photoIndex === images.length - 1) {
      setPhotoIndex(0);
    }
  };
  const backPhoto = () => {
    if (photoIndex !== 0) {
      setPhotoIndex(photoIndex - 1);
    } else if (photoIndex === 0) {
      setPhotoIndex(images.length - 1);
    }
  };
  const setPhotoFromIndex = (index: number) => {
    setPhotoIndex(index);
  };
  return (
    <>
      <Display style={{ backgroundColor: images[photoIndex].backgroundColor }}>
        <IconButtonStyled
          onClick={backPhoto}
          aria-label='back'
          style={{
            backgroundColor: theme.palette.background.default,
            opacity: 0.25,
          }}
        >
          <ArrowBackIosIcon style={{ fontSize: 36 }} />
        </IconButtonStyled>
        <Box width='100%'>
          <GatsbyImage
            style={{ height: 600, maxHeight: '80vh' }}
            image={images[photoIndex]}
            objectFit='contain'
            alt={' '}
          />
        </Box>

        <IconButtonStyled
          onClick={nextPhoto}
          aria-label='next'
          style={{
            backgroundColor: theme.palette.background.default,
            opacity: 0.25,
          }}
        >
          <ArrowForwardIosIcon style={{ fontSize: 36 }} />
        </IconButtonStyled>
      </Display>
      <Box display='flex' justifyContent='center' mt='1rem'>
        {images.map((image, i) => (
          <NavIndicator
            onClick={() => setPhotoFromIndex(i)}
            style={{
              backgroundColor: photoIndex === i ? '#212121' : undefined,
            }}
          />
        ))}
      </Box>
    </>
  );
};

export default ImageGallery;

const IconButtonStyled = styled(Button)``;
const Display = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavIndicator = styled.button`
  padding: 0px;
  cursor: pointer;
  width: 10px;
  height: 10px;
  border: 2px solid #212121;
  border-radius: 10px;
  margin: 0 0.25rem 0 0.25rem;
  background: none;
`;
